.image-gallery-image {
  width: 100%;
  height: auto;
  background-color: #a4a7a4;
}
.fullscreen .image-gallery-slide img {
  max-height: 100%;
  height: auto;
}

.image-gallery-slide img {
  width: 100%;
  height: 412px;
}

.image-gallery-content .image-gallery-image {
  height: 515px;
}

.image-gallery-icon:hover {
  color: #3e984c;
}

.image-gallery.fullscreen-modal {
  z-index: 9999;
}
